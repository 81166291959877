<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-center">
              <h1 class="heading-1">Privacy Notice</h1>
              <p class="sub-title">Last updated March 2, 2022</p>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="pt-4">
              <p class="text">
                Thank you for choosing to be part of the Company's community at
                Resumation LLC ("<span class="bold">Company</span>”). The
                Company is committed to protecting your personal information and
                your right to privacy. If you have any questions or concerns
                about this privacy notice or the Company's practices with regard
                to your personal information, please contact the Company at
                <a href="mailto:info@resumation.net">info@resumation.net</a>.
              </p>
              <p class="text">
                This privacy notice describes how the Company might use your
                information if you:
              </p>
              <ul>
                <li class="text">
                  Visit the Company's website at
                  <a href="https://www.resumation.net" target="_blank"
                    >https://www.resumation.net</a
                  >
                </li>
                <li class="text">
                  Engage with the Company in other related ways ― including any
                  sales, marketing, or events
                </li>
              </ul>
              <br />
              <p class="text">
                In this privacy notice, if the Company refers to:
              </p>
              <ul>
                <li class="text">
                  "<span class="bold">Website</span>," The Company is referring
                  to any website of ours that references or links to this notice
                </li>
                <li class="text">
                  "<span class="bold">Services</span>," The Company is referring
                  to the Company's Website, and other related services,
                  including any sales, marketing, or events
                </li>
              </ul>
              <br />
              <p class="text">
                The purpose of this privacy notice is to explain to you in the
                clearest way possible what information the Company collects, how
                the Company uses it, and what rights you have in relation to it.
                If there are any terms in this privacy notice that you do not
                agree with, please discontinue use of the Company's Services
                immediately.
              </p>
              <p class="text bold">
                Please read this privacy notice carefully, as it will help you
                understand what the Company does with the information that the
                Company collects.
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-2 upper">TABLE OF CONTENTS</p>
              <ol>
                <a href="#1">
                  <li class="text upper">
                    WHAT INFORMATION DOES THE COMPANY COLLECT?
                  </li>
                </a>
                <a href="#2">
                  <li class="text upper">
                    HOW DOES THE COMPANY USES YOUR INFORMATION?
                  </li>
                </a>
                <a href="#3"
                  ><li class="text upper">
                    WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </li></a
                >
                <a href="#4"
                  ><li class="text upper">
                    WHO WILL YOUR INFORMATION BE SHARED WITH?
                  </li></a
                >
                <a href="#5"
                  ><li class="text upper">
                    DOES THE COMPANY USES COOKIES AND OTHER TRACKING
                    TECHNOLOGIES?
                  </li></a
                >
                <a href="#6"
                  ><li class="text upper">
                    HOW DOES THE COMPANY HANDLE YOUR SOCIAL LOGINS?
                  </li></a
                >
                <a href="#7"
                  ><li class="text upper">
                    WHAT IS THE COMPANY'S STANCE ON THIRD-PARTY WEBSITES?
                  </li></a
                >
                <a href="#8"
                  ><li class="text upper">
                    HOW LONG DOES THE COMPANY KEEP YOUR INFORMATION?
                  </li></a
                >
                <a href="#9"
                  ><li class="text upper">
                    HOW DOES THE COMPANY KEEP YOUR INFORMATION SAFE?
                  </li></a
                >
                <a href="#10"
                  ><li class="text upper">
                    DOES THE COMPANY COLLECT INFORMATION FROM MINORS?
                  </li></a
                >
                <a href="#11"
                  ><li class="text upper">
                    DOES THE COMPANY COLLECT INFORMATION FROM RESIDENTS IN THE
                    EEA OR UK?
                  </li></a
                >
                <a href="#12"
                  ><li class="text upper">WHAT ARE YOUR PRIVACY RIGHTS?</li></a
                >
                <a href="#13"
                  ><li class="text upper">
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                  </li></a
                >
                <a href="#14"
                  ><li class="text upper">
                    DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </li></a
                >
                <a href="#15"
                  ><li class="text upper">
                    DOES THE COMPANY MAKE UPDATES TO THIS NOTICE?
                  </li></a
                >
                <a href="#16"
                  ><li class="text upper">
                    HOW CAN YOU CONTACT THE COMPANY ABOUT THIS NOTICE?
                  </li></a
                >
                <a href="#17"
                  ><li class="text upper">
                    HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA THE COMPANY
                    COLLECTS FROM YOU?
                  </li></a
                >
              </ol>
            </div>
            <div class="pt-4">
              <a class="anchor" id="1"></a>
              <p class="heading-1">
                1. WHAT INFORMATION DOES THE COMPANY COLLECT?
              </p>
              <p class="heading-2">Personal information you disclose to us</p>
              <p class="text ital">
                <span class="bold">In Short: </span>The Company collects
                personal information that you provide to the Company.
              </p>
              <p class="text">
                The Company collects personal information that you voluntarily
                provide to the Company when you register on the Website, express
                an interest in obtaining information about the Company or the
                Company's products and Services, when you participate in
                activities on the Website or otherwise when you contact the
                Company.
              </p>
              <p class="text">
                The personal information that the Company collects depends on
                the context of your interactions with the Company and the
                Website, the choices you make and the products and features you
                use. The personal information the Company collects may include
                the following:
              </p>
              <p class="text">
                <span class="bold">
                  Personal Information Provided by You.
                </span>
                The Company collects names; phone numbers; email addresses;
                mailing addresses; job titles; usernames; contact preferences;
                contact or authentication data; occupation;
                personal/professional experience; licenses/certifications/other
                qualifications; personal identifiers; internet activity; online
                identifiers; geolocation information; education information;
                work information; volunteer information; personal/professional
                skills; inferences from other personal information; information
                for personal development; information for professional
                development; other information specific to physical,
                physiological, genetic, economic, cultural, or social identity
                like religious affiliation, ethnic origin, etc; academic
                degrees; and other similar information.
              </p>
              <p class="text">
                <span class="bold">Social Media Login Data.</span>
                The Company may provide you with the option to register with the
                Company using your existing social media account details, like
                your Facebook, Twitter or other social media account. If you
                choose to register in this way, The Company will collect the
                information described in the section called "
                <a href="#6">HOW DOES THE COMPANY HANDLE YOUR SOCIAL LOGINS?</a>
                " below.
              </p>
              <p class="text">
                All personal information that you provide to the Company must be
                true, complete and accurate, and you must notify the Company of
                any changes to such personal information.
              </p>
              <p class="heading-2">Information automatically collected</p>
              <p class="text ital">
                <span class="bold">In Short: </span>: Some information — such as
                your Internet Protocol (IP) address and/or browser and device
                characteristics — is collected automatically when you visit the
                Company's Website.
              </p>
              <p class="text">
                The Company automatically collects certain information when you
                visit, use or navigate the Website. This information does not
                reveal your specific identity (like your name or contact
                information) but may include device and usage information, such
                as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device
                name, country, location, information about how and when you use
                the Company's Website and other technical information. This
                information is primarily needed to maintain the security and
                operation of the Company's Website, and for the Company's
                internal analytics and reporting purposes.
              </p>
              <p class="text">
                Like many businesses, the Company also collects information
                through cookies and similar technologies. You can find out more
                about this in the Company's Cookie Notice:
                <a
                  href="https://www.resumation.net/CookieNotice"
                  target="_blank"
                  >https://www.resumation.net/CookieNotice</a
                >.
              </p>
              <p class="text">The information the Company collects includes:</p>
              <ul>
                <li>
                  <p class="text">
                    <span class="ital">Log and Usage Data.</span>
                    Log and usage data is service-related, diagnostic, usage and
                    performance information the Company's servers automatically
                    collect when you access or use the Company's Website and
                    which the Company records in log files. Depending on how you
                    interact with us, this log data may include your IP address,
                    device information, browser type and settings and
                    information about your activity in the Website (such as the
                    date/time stamps associated with your usage, pages and files
                    viewed, searches and other actions you take such as which
                    features you use), device event information (such as system
                    activity, error reports (sometimes called 'crash dumps') and
                    hardware settings).
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="ital">Device Data.</span>
                    The Company collects device data such as information about
                    your computer, phone, tablet or other device you use to
                    access the Website. Depending on the device used, this
                    device data may include information such as your IP address
                    (or proxy server), device and application identification
                    numbers, location, browser type, hardware model Internet
                    service provider and/or mobile carrier, operating system and
                    system configuration information.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="ital">Location Data.</span>
                    The Company collects location data such as information about
                    your device's location, which can be either precise or
                    imprecise. How much information the Company collects depends
                    on the type and settings of the device you use to access the
                    Website. For example, the Company may use GPS and other
                    technologies to collect geolocation data that tells the
                    Company your current location (based on your IP address).
                    You can opt out of allowing the Company to collect this
                    information either by refusing access to the information or
                    by disabling your Location setting on your device. Note
                    however, if you choose to opt out, you may not be able to
                    use certain aspects of the Services.
                  </p>
                </li>
              </ul>
            </div>
            <div class="pt-4">
              <a class="anchor" id="2"></a>
              <p class="heading-1">
                2. HOW DOES THE COMPANY USES YOUR INFORMATION?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>The Company processes your
                information for purposes based on legitimate business interests,
                the fulfillment of the Company's contract with you, compliance
                with the Company's legal obligations, and/or your consent.
              </p>
              <p class="text">
                The Company uses personal information collected via the
                Company's Website for a variety of business purposes described
                below. The Company processes your personal information for these
                purposes in reliance on the Company's legitimate business
                interests, in order to enter into or perform a contract with
                you, with your consent, and/or for compliance with the Company's
                legal obligations. The Company indicate the specific processing
                grounds the Company relies on next to each purpose listed below.
              </p>
              <p class="text">
                The Company uses the information The Company collects or
                receive:
              </p>
              <ul>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To facilitate account creation and logon process.</span
                    >
                    If you choose to link your account with the Company to a
                    third-party account (such as your Google or Facebook
                    account), The Company uses the information you allowed the
                    Company to collect from those third parties to facilitate
                    account creation and logon process for the performance of
                    the contract. See the section below headed "<a href="#6"
                      >HOW DOES THE COMPANY HANDLE YOUR SOCIAL LOGINS?</a
                    >" for further information.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">To post testimonials.</span>
                    The Company posts testimonials on the Company's Website that
                    may contain personal information. Prior to posting a
                    testimonial, the Company will obtain your consent to use
                    your name and the content of the testimonial. If you wish to
                    update, or delete your testimonial, please contact the
                    Company at privacy@resumation.net and be sure to include
                    your name, testimonial location, and contact information.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Request feedback.</span>
                    The Company may use your information to request feedback and
                    to contact you about your use of the Company's Website.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To enable user-to-user communications.</span
                    >
                    The Company may use your information in order to enable
                    user-to-user communications with each user's consent.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">To manage user accounts.</span>
                    The Company may use your information for the purposes of
                    managing the Company's account and keeping it in working
                    order.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To send administrative information to you.</span
                    >
                    The Company may use your personal information to send you
                    product, service and new feature information and/or
                    information about changes to the Company's terms,
                    conditions, and policies.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To protect the Company's Services.
                    </span>
                    The Company may use your information as part of the
                    Company's efforts to keep the Company's Website safe and
                    secure (for example, for fraud monitoring and prevention).
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To enforce the Company's terms, conditions and policies
                      for business purposes, to comply with legal and regulatory
                      requirements or in connection with the Company's
                      contract.</span
                    >
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To respond to legal requests and prevent harm.</span
                    >
                    If the Company receives a subpoena or other legal request,
                    the Company may need to inspect the data the Company holds
                    to determine how to respond.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Fulfill and manage your orders.</span>
                    The Company may use your information to fulfill and manage
                    your orders, payments, returns, and exchanges made through
                    the Website.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >Administer prize draws and competitions.</span
                    >
                    The Company may use your information to administer prize
                    draws and competitions when you elect to participate in the
                    Company's competitions.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To deliver and facilitate delivery of services to the
                      user.</span
                    >
                    The Company may use your information to provide you with the
                    requested service.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To respond to user inquiries/offer support to
                      users.</span
                    >
                    The Company may use your information to respond to your
                    inquiries and solve any potential issues you might have with
                    the use of the Company's Services.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >To send you marketing and promotional
                      communications.</span
                    >
                    The Company and/or the Company's third-party marketing
                    partners may use the personal information you send to the
                    Company for the Company's marketing purposes, if this is in
                    accordance with your marketing preferences. For example,
                    when expressing an interest in obtaining information about
                    the Company or the Company's Website, subscribing to
                    marketing or otherwise contacting us, The Company will
                    collect personal information from you. You can opt-out of
                    the Company's marketing emails at any time (see the "<a
                      href="#12"
                      >WHAT ARE YOUR PRIVACY RIGHTS?</a
                    >" below).
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >Deliver targeted advertising to you.</span
                    >
                    The Company may use your information to develop and display
                    personalized content and advertising (and work with third
                    parties who do so) tailored to your interests and/or
                    location and to measure its effectiveness. For more
                    information see the Company's Cookie Notice:
                    <a
                      href="https://www.resumation.net/CookieNotice"
                      target="_blank"
                      >https://www.resumation.net/CookieNotice</a
                    >.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">For other business purposes.</span>
                    The Company may use your information for other business
                    purposes, such as data analysis, identifying usage trends,
                    determining the effectiveness of the Company's promotional
                    campaigns and to evaluate and improve the Company's Website,
                    products, marketing and your experience. The Company may use
                    and store this information in aggregated and anonymized form
                    so that it is not associated with individual end users and
                    does not include personal information.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">
                      Anonymized Data and Analytics Sale &#38; Services
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div class="pt-4">
              <a class="anchor" id="3"></a>
              <p class="heading-1">
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>The Company only shares
                information with your consent, to comply with laws, to provide
                you with services, to protect your rights, or to fulfill
                business obligations.
              </p>
              <p class="text">
                The Company may process or share your data that the Company
                holds based on the following legal basis:
              </p>
              <ul>
                <li>
                  <p class="text">
                    <span class="bold">Consent:</span>
                    The Company may process your data if you have given the
                    Company specific consent to use your personal information
                    for a specific purpose.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Legitimate Interests:</span>
                    The Company may process your data when it is reasonably
                    necessary to achieve the Company's legitimate business
                    interests.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Performance of a Contract:</span>
                    Where the Company has entered into a contract with you, the
                    Company may process your personal information to fulfill the
                    terms of the Company's contract.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Legal Obligations:</span>
                    The Company may disclose your information where the Company
                    is legally required to do so in order to comply with
                    applicable law, governmental requests, a judicial
                    proceeding, court order, or legal process, such as in
                    response to a court order or a subpoena (including in
                    response to public authorities to meet national security or
                    law enforcement requirements).
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Vital Interests:</span>
                    The Company may disclose your information where the Company
                    believes it is necessary to investigate, prevent, or take
                    action regarding potential violations of the Company's
                    policies, suspected fraud, situations involving potential
                    threats to the safety of any person and illegal activities,
                    or as evidence in litigation in which the Company is
                    involved.
                  </p>
                </li>
              </ul>
              <p class="text">
                More specifically, the Company may need to process your data or
                share your personal information in the following situations:
              </p>
              <ul>
                <li>
                  <p class="text">
                    <span class="bold">Business Transfers.</span>
                    The Company may share or transfer your information in
                    connection with, or during negotiations of, any merger, sale
                    of company assets, financing, or acquisition of all or a
                    portion of the Company's business to another company.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold"
                      >Vendors, Consultants and Other Third-Party Service
                      Providers.</span
                    >
                    The Company may share your data with third-party vendors,
                    service providers, contractors or agents who perform
                    services for the Company or on the Company's behalf and
                    require access to such information to do that work. Examples
                    include: payment processing, data analysis, email delivery,
                    hosting services, customer service and marketing efforts.
                    The Company may allow selected third parties to use tracking
                    technology on the Website, which will enable them to collect
                    data on the Company's behalf about how you interact with the
                    Company's Website over time. This information may be used
                    to, among other things, analyze and track data, determine
                    the popularity of certain content, pages or features, and
                    better understand online activity. Unless described in this
                    notice, The Company does not share, sell, rent or trade any
                    of your information with third parties for their promotional
                    purposes.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Third-Party Advertisers.</span>
                    The Company may use third-party advertising companies to
                    serve ads when you visit or use the Website. These companies
                    may use information about your visits to the Company's
                    Website(s) and other websites that are contained in web
                    cookies and other tracking technologies in order to provide
                    advertisements about goods and services of interest to you.
                    See the Company's Cookie Notice:
                    <a
                      href="https://www.resumation.net/CookieNotice"
                      target="_blank"
                      >https://www.resumation.net/CookieNotice</a
                    >
                    for further information.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Affiliates.</span>
                    The Company may share your information with the Company's
                    affiliates, in which case the Company will require those
                    affiliates to honor this privacy notice. Affiliates include
                    the Company's parent company and any subsidiaries, joint
                    venture partners or other companies that the Company
                    controls or that are under common control with the Company.
                  </p>
                </li>
                <li>
                  <p class="text">
                    <span class="bold">Business Partners.</span>
                    The Company may share your information with the Company's
                    business partners to offer you certain products, services or
                    promotions.
                  </p>
                </li>
              </ul>
            </div>
            <div class="pt-4">
              <a class="anchor" id="4"></a>
              <p class="heading-1">
                4. WHO WILL YOUR INFORMATION BE SHARED WITH?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>The Company only shares
                information with the following categories of third parties.
              </p>
              <p class="text">
                The Company only shares and discloses your information with the
                following categories of third parties. If the Company has
                processed your data based on your consent and you wish to revoke
                your consent, please contact the Company using the contact
                details provided in the section below titled "<a href="#16"
                  >HOW CAN YOU CONTACT THE COMPANY ABOUT THIS NOTICE?</a
                >".
              </p>
              <ul>
                <li><p class="text">Ad Networks</p></li>
                <li><p class="text">Affiliate Marketing Programs</p></li>
                <li><p class="text">Cloud Computing Services</p></li>
                <li>
                  <p class="text">Communication &#38; Collaboration Tools</p>
                </li>
                <li><p class="text">Data Analytics Services</p></li>
                <li><p class="text">Data Storage Service Providers</p></li>
                <li><p class="text">Finance &#38; Accounting Tools</p></li>
                <li><p class="text">Order Fulfillment Service Providers</p></li>
                <li><p class="text">Government Entities</p></li>
                <li><p class="text">Payment Processors</p></li>
                <li><p class="text">Performance Monitoring Tools</p></li>
                <li>
                  <p class="text">Product Engineering &#38; Design Tools</p>
                </li>
                <li><p class="text">Retargeting Platforms</p></li>
                <li><p class="text">Sales &#38; Marketing Tools</p></li>
                <li><p class="text">Social Networks</p></li>
                <li><p class="text">Testing Tools</p></li>
                <li>
                  <p class="text">
                    User Account Registration &#38; Authentication Services
                  </p>
                </li>
                <li>
                  <p class="text">Universities Foundations / Endowments</p>
                </li>
                <li><p class="text">Job Boards</p></li>
                <li><p class="text">Recruiters</p></li>
                <li><p class="text">Job Search Websites</p></li>
                <li>
                  <p class="text">Universities / Colleges and Affiliates</p>
                </li>
                <li><p class="text">Website Hosting Service Providers</p></li>
              </ul>
            </div>
            <div class="pt-4">
              <a class="anchor" id="5"></a>
              <p class="heading-1">
                5. DOES THE COMPANY USES COOKIES AND OTHER TRACKING
                TECHNOLOGIES?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>The Company may use cookies
                and other tracking technologies to collect and store your
                information.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="6"></a>
              <p class="heading-1">
                6. HOW DOES THE COMPANY HANDLE YOUR SOCIAL LOGINS?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>
                If you choose to register or log in to the Company's services
                using a social media account, the Company may have access to
                certain information about you.
              </p>
              <p class="text">
                Our Website offers you the ability to register and login using
                your third-party social media account details (like your
                Facebook or Twitter logins). Where you choose to do this, The
                Company will receive certain profile information about you from
                your social media provider. The profile information the Company
                receives may vary depending on the social media provider
                concerned, but will often include your name, email address,
                friends list, profile picture as well as other information you
                choose to make public on such social media platform.
              </p>
              <p class="text">
                The Company will use the information The Company receives only
                for the purposes that are described in this privacy notice or
                that are otherwise made clear to you on the relevant Website.
                Please note that the Company do not control, and are not
                responsible for, other uses of your personal information by your
                third-party social media provider. The Company recommends that
                you review their privacy notice to understand how they collect,
                use and share your personal information, and how you can set
                your privacy preferences on their sites and apps.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="7"></a>
              <p class="heading-1">
                7. WHAT IS THE COMPANY'S STANCE ON THIRD-PARTY WEBSITES?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>
                The Company is not responsible for the safety of any information
                that you share with third-party providers who advertise, but are
                not affiliated with, the Company's Website.
              </p>
              <p class="text">
                The Website may contain advertisements from third parties that
                are not affiliated with the Company and which may link to other
                websites, online services or mobile applications. The Company
                cannot guarantee the safety and privacy of data you provide to
                any third parties. Any data collected by third parties is not
                covered by this privacy notice. The Company is not responsible
                for the content or privacy and security practices and policies
                of any third parties, including other websites, services or
                applications that may be linked to or from the Website. You
                should review the policies of such third parties and contact
                them directly to respond to your questions.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="8"></a>
              <p class="heading-1">
                8. HOW LONG DOES THE COMPANY KEEP YOUR INFORMATION?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>
                The Company keeps your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
              </p>
              <p class="text">
                The Company will only keep your personal information for as long
                as it is necessary for the purposes set out in this privacy
                notice, unless a longer retention period is required or
                permitted by law (such as tax, accounting or other legal
                requirements). No purpose in this notice will require the
                Company keeping your personal information for longer than the
                period of time in which users have an account with the Company.
              </p>
              <p class="text">
                When the Company has no ongoing legitimate business need to
                process your personal information, the Company will either
                delete or anonymize such information, or, if this is not
                possible (for example, because your personal information has
                been stored in backup archives), then the Company will securely
                store your personal information and isolate it from any further
                processing until deletion is possible.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="9"></a>
              <p class="heading-1">
                9. HOW DOES THE COMPANY KEEP YOUR INFORMATION SAFE?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>
                The Company aims to protect your personal information through a
                system of organizational and technical security measures.
              </p>
              <p class="text">
                The Company has implemented appropriate technical and
                organizational security measures designed to protect the
                security of any personal information the Company processes.
                However, despite the Company's safeguards and efforts to secure
                your information, no electronic transmission over the Internet
                or information storage technology can be guaranteed to be 100%
                secure, so the Company cannot promise or guarantee that hackers,
                cybercriminals, or other unauthorized third parties will not be
                able to defeat the Company's security, and improperly collect,
                access, steal, or modify your information. Although The Company
                will do the Company's best to protect your personal information,
                transmission of personal information to and from the Company's
                Website is at your own risk. You should only access the Website
                within a secure environment.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="10"></a>
              <p class="heading-1">
                10. DOES THE COMPANY COLLECT INFORMATION FROM MINORS?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>
                The Company does not knowingly collect data from or market to
                children under 18 years of age.
              </p>
              <p class="text">
                The Company does not knowingly solicit data from or market to
                children under 18 years of age. By using the Website, you
                represent that you are at least 18 or that you are the parent or
                guardian of such a minor and consent to such minor dependent's
                use of the Website. If the Company learn that personal
                information from users less than 18 years of age has been
                collected, the Company will deactivate the account and take
                reasonable measures to promptly delete such data from the
                Company's records. If you become aware of any data the Company
                may have collected from children under age 18, please contact
                the Company at
                <a href="mailto:privacy@resumation.net"
                  >privacy@resumation.net</a
                >.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="11"></a>
              <p class="heading-1">
                11. DOES THE COMPANY COLLECT INFORMATION FROM RESIDENTS IN THE
                EEA OR UK?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>
                The Company does not knowingly collect data from or market to
                residents in the EEA or UK.
              </p>
              <p class="text">
                The Company does not knowingly solicit data from or market to
                residents in the EEA or UK. By using the Website, you represent
                that you are not a resident in the EEA or UK. If the Company
                learn that personal information from users that are residents in
                the EEA or UK has been collected, the Company will deactivate
                the account and take reasonable measures to promptly delete such
                data from the Company's records. If you become aware of any data
                the Company may have collected from residents in the EEA or UK,
                please contact the Company at
                <a href="mailto:privacy@resumation.net"
                  >privacy@resumation.net</a
                >.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="12"></a>
              <p class="heading-1">12. WHAT ARE YOUR PRIVACY RIGHTS?</p>
              <p class="text ital">
                <span class="bold">In Short: </span>You may review, change, or
                terminate your account at any time.
              </p>
              <p class="text">
                If you are a resident in the EEA or UK and you believe the
                Company is unlawfully processing your personal information, you
                also have the right to complain to your local data protection
                supervisory authority. You can find their contact details here:
                <a
                  href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                  target="_blank"
                  >http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a
                >.
              </p>
              <p class="text">
                If you are a resident in Switzerland, the contact details for
                the data protection authorities are available here:
                <a
                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                  target="blank"
                  >https://www.edoeb.admin.ch/edoeb/en/home.html</a
                >.
              </p>
              <p class="text">
                If you have questions or comments about your privacy rights, you
                may email the Company at
                <a href="mailto:privacy@resumation.net"
                  >privacy@resumation.net</a
                >.
              </p>
              <p class="heading-2">Account Information</p>
              <p class="text">
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </p>
              <ul>
                <li>
                  <p class="text">
                    Log in to your account settings and update your user
                    account.
                  </p>
                </li>
              </ul>
              <p class="text">
                Upon your request to terminate your account, the Company will
                deactivate or delete your account and information from the
                Company's active databases. However, the Company may retain some
                information in the Company's files to prevent fraud,
                troubleshoot problems, assist with any investigations, enforce
                the Company's Terms of Use and/or comply with applicable legal
                requirements.
              </p>
              <p class="text">
                <span class="bold">Cookies and similar technologies:</span>
                Most Web browsers are set to accept cookies by default. If you
                prefer, you can usually choose to set your browser to remove
                cookies and to reject cookies. If you choose to remove cookies
                or reject cookies, this could affect certain features or
                services of the Company's Website. To opt-out of interest-based
                advertising by advertisers on the Company's Website visit
                <a href="https://www.aboutads.info/choices/" target="_blank"
                  >https://www.aboutads.info/choices/</a
                >. For further information, please see the Company's Cookie
                Notice:
                <a
                  href="https://www.resumation.net/CookieNotice"
                  target="_blank"
                  >https://www.resumation.net/CookieNotice</a
                >.
              </p>
              <p class="text">
                <span class="bold">Opting out of email marketing:</span>
                You can unsubscribe from the Company's marketing email list at
                any time by clicking on the unsubscribe link in the emails that
                the Company sends or by contacting the Company using the details
                provided below. You will then be removed from the marketing
                email list — however, the Company may still communicate with
                you, for example to send you service-related emails that are
                necessary for the administration and use of your account, to
                respond to service requests, or for other non-marketing
                purposes. To otherwise opt-out, you may:
              </p>
              <ul>
                <li>
                  <p class="text">Unsubscribe</p>
                </li>
              </ul>
            </div>
            <div class="pt-4">
              <a class="anchor" id="13"></a>
              <p class="heading-1">13. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
              <p class="text">
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, the Company does not currently respond to DNT browser
                signals or any other mechanism that automatically communicates
                your choice not to be tracked online. If a standard for online
                tracking is adopted that the Company must follow in the future,
                the Company will inform you about that practice in a revised
                version of this privacy notice.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="14"></a>
              <p class="heading-1">
                14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>
                Yes, if you are a resident of California, you are granted
                specific rights regarding access to your personal information.
              </p>
              <p class="text">
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits the Company's users who are California
                residents to request and obtain from the Company, once a year
                and free of charge, information about categories of personal
                information (if any) the Company disclosed to third parties for
                direct marketing purposes and the names and addresses of all
                third parties with which the Company shared personal information
                in the immediately preceding calendar year. If you are a
                California resident and would like to make such a request,
                please submit your request in writing to the Company using the
                contact information provided below.
              </p>
              <p class="text">
                If you are under 18 years of age, reside in California, and have
                a registered account with the Website, you have the right to
                request removal of unwanted data that you publicly post on the
                Website. To request removal of such data, please contact the
                Company using the contact information provided below, and
                include the email address associated with your account and a
                statement that you reside in California. The Company will make
                sure the data is not publicly displayed on the Website, but
                please be aware that the data may not be completely or
                comprehensively removed from all the Company's systems (e.g.
                backups, etc.).
              </p>
              <p class="heading-2">CCPA Privacy Notice</p>
              <p class="text">
                The California Code of Regulations defines a "resident" as:
              </p>
              <p class="text">
                (1) every individual who is in the State of California for other
                than a temporary or transitory purpose and<br />
                (2) every individual who is domiciled in the State of California
                who is outside the State of California for a temporary or
                transitory purpose
              </p>
              <p class="text">
                All other individuals are defined as "non-residents."
              </p>
              <p class="text">
                If this definition of "resident" applies to you, the Company
                must adhere to certain rights and obligations regarding your
                personal information.
              </p>
              <p class="heading-3">
                What categories of personal information does the Company
                collect?
              </p>
              <p class="text">
                The Company has collected the following categories of personal
                information in the past twelve (12) months:
              </p>
              <table>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                  <th>Collected</th>
                </tr>
                <tr>
                  <td>A. Identifiers</td>
                  <td>
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address and account name
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>
                    B. Personal information categories listed in the California
                    Customer Records statute
                  </td>
                  <td>
                    Name, contact information, education, employment, employment
                    history and financial information
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>
                    C. Protected classification characteristics under California
                    or federal law
                  </td>
                  <td>Gender and date of birth</td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>D. Commercial information</td>
                  <td>
                    Transaction information, purchase history, financial details
                    and payment information
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>E. Biometric information</td>
                  <td>Fingerprints and voiceprints</td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>F. Internet or other similar network activity</td>
                  <td>
                    Browsing history, search history, online behavior, interest
                    data, and interactions with the Company's and other
                    websites, applications, systems and advertisements
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>G. Geolocation data</td>
                  <td>Device location</td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </td>
                  <td>
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </td>
                  <td>NO</td>
                </tr>
                <tr>
                  <td>I. Professional or employment-related information</td>
                  <td>
                    Business contact details in order to provide you the
                    Company's services at a business level, job title as well as
                    work history and professional qualifications if you apply
                    for a job with us
                  </td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>J. Education Information</td>
                  <td>Student records and directory information</td>
                  <td>YES</td>
                </tr>
                <tr>
                  <td>K. Inferences drawn from other personal information</td>
                  <td>
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual's preferences and
                    characteristics
                  </td>
                  <td>YES</td>
                </tr>
              </table>

              <br />
              <p class="text">
                The Company may also collect other personal information outside
                of these categories instances where you interact with the
                Company in-person, online, or by phone or mail in the context
                of:
              </p>
              <ul>
                <li>
                  <p class="text">
                    Receiving help through the Company's customer support
                    channels;
                  </p>
                </li>
                <li>
                  <p class="text">
                    Participation in customer surveys or contests; and
                  </p>
                </li>
                <li>
                  <p class="text">
                    Facilitation in the delivery of the Company's Services and
                    to respond to your inquiries.
                  </p>
                </li>
              </ul>
              <p class="heading-3">
                How does the Company use and share your personal information?
              </p>
              <p class="text">
                More information about the Company's data collection and sharing
                practices can be found in this privacy notice and the Company's
                Cookie Notice:
                <a
                  href="https://www.resumation.net/CookieNotice"
                  target="_blank"
                  >https://www.resumation.net/CookieNotice</a
                >.
              </p>
              <p class="text">
                You can opt out from the selling of your personal information by
                disabling cookies in the Cookie Manager.
              </p>
              <p class="text">
                You may contact the Company by email at
                <a href="mailto:privacy@resumation.net"
                  >privacy@resumation.net</a
                >, or by referring to the contact details at the bottom of this
                document.
              </p>
              <p class="text">
                If you are using an authorized agent to exercise your right to
                opt-out the Company may deny a request if the authorized agent
                does not submit proof that they have been validly authorized to
                act on your behalf.
              </p>
              <p class="heading-3">
                Will your information be shared with anyone else?
              </p>
              <p class="text">
                The Company may disclose your personal information with the
                Company's service providers pursuant to a written contract
                between the Company and each service provider. Each service
                provider is a for-profit entity that processes the information
                on the Company's behalf.
              </p>
              <p class="text">
                The Company may use your personal information for the Company's
                own business purposes, such as for undertaking internal research
                for technological development and demonstration. This is not
                considered to be "selling" of your personal data.
              </p>
              <p class="text">
                Resumation LLC has disclosed the following categories of
                personal information to third parties for a business or
                commercial purpose in the preceding twelve (12) months:
              </p>
              <ul>
                <li>
                  <p class="text">
                    Category F. Internet or other electronic network activity
                    information, such as browsing history, search history,
                    online behavior, interest data, and interactions with the
                    Company's and other websites, applications, systems and
                    advertisements.
                  </p>
                </li>
                <li>
                  <p class="text">
                    Category K. Inferences drawn from any of the personal
                    information listed above to create a profile or summary
                    about, for example, an individual's preferences and
                    characteristics.
                  </p>
                </li>
              </ul>
              <p class="text">
                The categories of third parties to whom we disclosed personal
                information for a business or commercial purpose can be found
                under "<a href="#4">WHO WILL YOUR INFORMATION BE SHARED WITH?</a
                >".
              </p>
              <p class="heading-3">
                Your rights with respect to your personal data
              </p>
              <p class="text ul">
                Right to request deletion of the data - Request to delete
              </p>
              <p class="text">
                You can ask for the deletion of your personal information. If
                you ask the Company to delete your personal information, the
                Company will respect your request and delete your personal
                information, subject to certain exceptions provided by law, such
                as (but not limited to) the exercise by another consumer of his
                or her right to free speech, the Company's compliance
                requirements resulting from a legal obligation or any processing
                that may be required to protect against illegal activities.
              </p>
              <p class="text ul">Right to be informed - Request to know</p>
              <p class="text">
                Depending on the circumstances, you have a right to know:
              </p>
              <ul>
                <li>
                  <p class="text">
                    Whether the Company collects and uses your personal
                    information;
                  </p>
                </li>
                <li>
                  <p class="text">
                    The categories of personal information that the Company
                    collects;
                  </p>
                </li>
                <li>
                  <p class="text">
                    The purposes for which the collected personal information is
                    used;
                  </p>
                </li>
                <li>
                  <p class="text">
                    Whether the Company sells your personal information to third
                    parties;
                  </p>
                </li>
                <li>
                  <p class="text">
                    The categories of personal information that the Company sold
                    or disclosed for a business purpose;
                  </p>
                </li>
                <li>
                  <p class="text">
                    The categories of third parties to whom the personal
                    information was sold or disclosed for a business purpose;
                    and
                  </p>
                </li>
                <li>
                  <p class="text">
                    The business or commercial purpose for collecting or selling
                    personal information.
                  </p>
                </li>
              </ul>
              <p class="text">
                In accordance with applicable law, the Company is not obligated
                to provide or delete consumer information that is de-identified
                in response to a consumer request or to re-identify individual
                data to verify a consumer request.
              </p>
              <p class="text ul">
                Right to Non-Discrimination for the Exercise of a Consumer's
                Privacy Rights
              </p>
              <p class="text">
                The Company will not discriminate against you if you exercise
                your privacy rights.
              </p>
              <p class="text ul">Verification process</p>
              <p class="text">
                Upon receiving your request, the Company will need to verify
                your identity to determine you are the same person about whom
                the Company has the information in the Company's system. These
                verification efforts require the Company to ask you to provide
                information so that the Company can match it with information
                you have previously provided the Company. For instance,
                depending on the type of request you submit, the Company may ask
                you to provide certain information so that the Company can match
                the information you provide with the information the Company
                already have on file, or the Company may contact you through a
                communication method (e.g. phone or email) that you have
                previously provided to the Company. The Company may also use
                other verification methods as the circumstances dictate.
              </p>
              <p class="text">
                The Company will only use personal information provided in your
                request to verify your identity or authority to make the
                request. To the extent possible, The Company will avoid
                requesting additional information from you for the purposes of
                verification. If, however, the Company cannot verify your
                identity from the information already maintained by us, the
                Company may request that you provide additional information for
                the purposes of verifying your identity, and for security or
                fraud-prevention purposes. The Company will delete such
                additionally provided information as soon as the Company finish
                verifying you.
              </p>
              <p class="text ul">Other privacy rights</p>
              <ul>
                <li>
                  <p class="text">
                    You may object to the processing of your personal data
                  </p>
                </li>
                <li>
                  <p class="text">
                    You may request correction of your personal data if it is
                    incorrect or no longer relevant, or ask to restrict the
                    processing of the data
                  </p>
                </li>
                <li>
                  <p class="text">
                    You can designate an authorized agent to make a request
                    under the CCPA on your behalf. The Company may deny a
                    request from an authorized agent that does not submit proof
                    that they have been validly authorized to act on your behalf
                    in accordance with the CCPA.
                  </p>
                </li>
              </ul>
              <p class="text">
                To exercise these rights, you can contact the Company by email
                at
                <a href="mailto:privacy@resumation.net"
                  >privacy@resumation.net</a
                >, or by referring to the contact details at the bottom of this
                page. If you have a complaint about how the Company handles your
                data, the Company would like to hear from you.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="15"></a>
              <p class="heading-1">
                15. DOES THE COMPANY MAKE UPDATES TO THIS NOTICE?
              </p>
              <p class="text ital">
                <span class="bold">In Short: </span>Yes, the Company will update
                this notice as necessary to stay compliant with relevant laws.
              </p>
              <p class="text">
                The Company may update this privacy notice from time to time.
                The updated version will be indicated by an updated "Revised"
                date and the updated version will be effective as soon as it is
                accessible. If the Company make material changes to this privacy
                notice, The Company may notify you either by prominently posting
                a notice of such changes or by directly sending you a
                notification. The Company encourages you to review this privacy
                notice frequently to be informed of how the Company is
                protecting your information.
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="16"></a>
              <p class="heading-1">
                16. HOW CAN YOU CONTACT THE COMPANY ABOUT THIS NOTICE?
              </p>
              <p class="text">
                If you have questions or comments about this notice, you may
                email the Company at
                <a href="mailto:privacy@resumation.net"
                  >privacy@resumation.net</a
                >
                or by post to:
              </p>
              <p class="text bold no-space">Resumation LLC</p>
              <p class="text bold no-space">25 First Ave. SW STE A</p>
              <p class="text bold no-space">Watertown, SD 57201</p>
              <p class="text bold no-space">United States</p>
              <p class="text bold no-space">Phone: (605) 229-9056</p>
              <p class="text bold no-space">
                Email:
                <a href="mailto:info@resumation.net">info@resumation.net</a>
              </p>
            </div>
            <div class="pt-4">
              <a class="anchor" id="17"></a>
              <p class="heading-1">
                17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA THE COMPANY
                COLLECTS FROM YOU?
              </p>
              <p class="text">
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information the Company
                collects from you, change that information, or delete it in some
                circumstances. To request to review, or update your personal
                information, please email the Company at:
                <a href="mailto:privacy@resumation.net"
                  >privacy@resumation.net</a
                >. To delete your personal information, please visit:
                <a href="https://www.resumation.net/Options" target="_blank"
                  >https://www.resumation.net/Options</a
                >.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
export default {
  name: "PrivacyNotice",
  data: () => ({}),
  components: {},
};
</script>

<style>
.sub-title {
  color: var(--v-updatedText-base);
}
.heading-1 {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.5rem;
}
.heading-2 {
  font-weight: 500;
  font-size: 1.25rem;
}
.heading-3 {
  font-weight: 500;
}
.upper {
  text-transform: capitalize;
}
.text {
  font-size: 1rem;
}
.bold {
  font-weight: 500;
}
.no-space {
  margin-bottom: 0px !important;
}
.ital {
  font-style: italic;
}
.ul {
  text-decoration: underline;
}
table,
th,
td {
  border-width: 1px;
  border-color: var(--v-updatedText-base);
  border-style: solid;
  border-collapse: collapse;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
a.anchor {
  display: block;
  position: relative;
  top: -75px;
  visibility: hidden;
}
</style>
